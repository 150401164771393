/* barlow-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/barlow-v12-latin-regular.74266723.eot); /* IE9 Compat Modes */
  src: url(/static/media/barlow-v12-latin-regular.74266723.eot?#iefix)
      format('embedded-opentype'),
    /* IE6-IE8 */
      url(/static/media/barlow-v12-latin-regular.2bc76301.woff2)
      format('woff2'),
    /* Super Modern Browsers */
      url(/static/media/barlow-v12-latin-regular.fe16b275.woff)
      format('woff'),
    /* Modern Browsers */
      url(/static/media/barlow-v12-latin-regular.ad0e398f.ttf)
      format('truetype'),
    /* Safari, Android, iOS */
      url(/static/media/barlow-v12-latin-regular.5eadf7d4.svg#Barlow)
      format('svg'); /* Legacy iOS */
}
/* barlow-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/barlow-v12-latin-italic.099d7ac6.eot); /* IE9 Compat Modes */
  src: url(/static/media/barlow-v12-latin-italic.099d7ac6.eot?#iefix)
      format('embedded-opentype'),
    /* IE6-IE8 */
      url(/static/media/barlow-v12-latin-italic.382a3f64.woff2)
      format('woff2'),
    /* Super Modern Browsers */
      url(/static/media/barlow-v12-latin-italic.09028cee.woff)
      format('woff'),
    /* Modern Browsers */
      url(/static/media/barlow-v12-latin-italic.b0560e2a.ttf)
      format('truetype'),
    /* Safari, Android, iOS */
      url(/static/media/barlow-v12-latin-italic.e9807138.svg#Barlow)
      format('svg'); /* Legacy iOS */
}
/* barlow-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/barlow-v12-latin-700.a2df43a2.eot); /* IE9 Compat Modes */
  src: url(/static/media/barlow-v12-latin-700.a2df43a2.eot?#iefix)
      format('embedded-opentype'),
    /* IE6-IE8 */
      url(/static/media/barlow-v12-latin-700.c3609c36.woff2)
      format('woff2'),
    /* Super Modern Browsers */
      url(/static/media/barlow-v12-latin-700.1da4773c.woff)
      format('woff'),
    /* Modern Browsers */
      url(/static/media/barlow-v12-latin-700.f871ab62.ttf)
      format('truetype'),
    /* Safari, Android, iOS */
      url(/static/media/barlow-v12-latin-700.b7398c5f.svg#Barlow)
      format('svg'); /* Legacy iOS */
}

/* barlow-condensed-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/barlow-condensed-v12-latin-regular.fe13987d.eot); /* IE9 Compat Modes */
  src: url(/static/media/barlow-condensed-v12-latin-regular.fe13987d.eot?#iefix)
      format('embedded-opentype'),
    /* IE6-IE8 */
      url(/static/media/barlow-condensed-v12-latin-regular.c8e400bb.woff2)
      format('woff2'),
    /* Super Modern Browsers */
      url(/static/media/barlow-condensed-v12-latin-regular.59ced31f.woff)
      format('woff'),
    /* Modern Browsers */
      url(/static/media/barlow-condensed-v12-latin-regular.6d0fec79.ttf)
      format('truetype'),
    /* Safari, Android, iOS */
      url(/static/media/barlow-condensed-v12-latin-regular.2af56869.svg#BarlowCondensed)
      format('svg'); /* Legacy iOS */
}
/* barlow-condensed-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/barlow-condensed-v12-latin-600.530a28fd.eot); /* IE9 Compat Modes */
  src: url(/static/media/barlow-condensed-v12-latin-600.530a28fd.eot?#iefix)
      format('embedded-opentype'),
    /* IE6-IE8 */
      url(/static/media/barlow-condensed-v12-latin-600.16cc9e59.woff2)
      format('woff2'),
    /* Super Modern Browsers */
      url(/static/media/barlow-condensed-v12-latin-600.55abff26.woff)
      format('woff'),
    /* Modern Browsers */
      url(/static/media/barlow-condensed-v12-latin-600.37d9b168.ttf)
      format('truetype'),
    /* Safari, Android, iOS */
      url(/static/media/barlow-condensed-v12-latin-600.0cace1c1.svg#BarlowCondensed)
      format('svg'); /* Legacy iOS */
}
/* barlow-condensed-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/barlow-condensed-v12-latin-700.33520034.eot); /* IE9 Compat Modes */
  src: url(/static/media/barlow-condensed-v12-latin-700.33520034.eot?#iefix)
      format('embedded-opentype'),
    /* IE6-IE8 */
      url(/static/media/barlow-condensed-v12-latin-700.93674060.woff2)
      format('woff2'),
    /* Super Modern Browsers */
      url(/static/media/barlow-condensed-v12-latin-700.519d2395.woff)
      format('woff'),
    /* Modern Browsers */
      url(/static/media/barlow-condensed-v12-latin-700.d56114a4.ttf)
      format('truetype'),
    /* Safari, Android, iOS */
      url(/static/media/barlow-condensed-v12-latin-700.4fcaf259.svg#BarlowCondensed)
      format('svg'); /* Legacy iOS */
}
